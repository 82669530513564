
.preprint-search-page {
    .search {
        min-height: 700px;
        background: #fefefe;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 30px 30px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .left-search {
            width: 290px;
            div {
                h5 {
                    height: 56px;
                    line-height: 56px;
                    background: #bd1a2d;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: default;
                    padding-left: 30px;
                    box-sizing: border-box;
                    font-size: 18px;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }
            }

            ul {
                // border: 1px solid #e6e6e6;
                max-height: 188px;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                margin-bottom: 24px;

                li {
                    padding-left: 30px;
                    box-sizing: border-box;
                    height: 62px;
                    // line-height: 62px;
                    font-size: 18px;
                    border-bottom: 1px solid #e6e6e6;
                    background: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: default !important;
                    line-height: 1;
                    a {
                        display: block;
                        font-size: 18px;
                        color: #333;
                        cursor: pointer;
                        width: 230px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    span {
                        font-size: 16px;
                        color: #666;
                    }
                    i {
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
                .active-left {
                    background: #f7f7f7;
                    color: #bd1a2d;
                    cursor: pointer;
                    a {
                        color: #bd1a2d;
                    }
                }
                li:hover {
                    background: #f7f7f7;
                    cursor: pointer;
                }
                li:last-child {
                    border-bottom: none;
                }
            }
            ul.oa-ul {
                max-height: 300px;
            }
            div.class-wrap {
                ul {
                    max-height: 620px;
                    overflow-y: auto;
                }
            }
            div.year-wrap {
                ul {
                    max-height: 620px;
                    overflow-y: auto;
                }
            }
        }
        .right-search {
            width: 820px;
            padding-bottom: 26px;
            .second-search-wrap {
                width: 100%;
                .second-search {
                    width: 100%;
                    height: 80px;
                    box-sizing: border-box;
                    padding: 20px 30px 20px 20px;
                    background: #f0f0f0;
                    display: flex;
                    .second-title {
                        width: 101px;
                    }
                    .second-author {
                        width: 101px;
                    }
                    .second-keywords {
                        width: 121px;
                    }
                    .second-date {
                    }
                    .second-input {
                        background: #ffffff;
                        border-radius: 2px;
                        border: none;
                        margin-right: 10px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        color: #666666;
                        font-size: 14px;
                    }
                    button {
                        width: 147px;
                        color: #fff;
                        border: none;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        background: #a91728;
                        margin-left: 10px;
                    }
                    button:hover {
                        opacity: 0.8;
                    }
                }
                .search-option {
                    display: flex;
                    justify-content: space-between;
                    margin: 13px 0 12px;

                    div {
                        display: flex;
                        color: #404040;
                        font-size: 14px;
                        span {
                            display: block;
                        }
                    }
                    .search-option1 {
                        display: block;
                        width: 100%;
                        padding-left: 19px;
                        //flex-wrap: wrap;
                        //justify-content: space-between;
                        .total {
                            display: inline-block;
                            font-size: 14px;
                            margin-right: 40px;
                            span {
                                display: inherit;
                                color: #ffa200;
                            }
                        }
                        a {
                            margin-right: 30px;
                        }
                        .search-option2 {
                            padding-right: 2px;
                            float: right;
                            span.active-sort {
                                color: #ffa200;
                            }
                            .sort1 {
                                margin-right: 4px;
                                cursor: pointer;
                            }
                            .sort2 {
                                margin-left: 4px;
                                cursor: pointer;
                            }
                        }
                    }

                }
                ul.result-list {
                    li {
                        padding: 20px;
                        background: #ffffff;
                        border: 1px solid #f5f5f5;
                        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                        margin-bottom: 24px;
                        display: flex;
                        div {
                            position: relative;
                            img {
                                width: 148px;
                                height: 198px;
                                background: #ceb08d;
                                border: 1px solid #e6e6e6;
                                box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                                // margin-right: 13px;
                                cursor: pointer;
                            }
                        }
                        .tag-book::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 42px;
                            height: 42px;
                            background: url(../assets/img/tag-book.png);
                        }
                        .tag-wenji::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 42px;
                            height: 42px;
                            background: url(../assets/img/tag-wenji.png);
                        }

                        .right-result {
                            h4 {
                                display: flex;
                                justify-content: space-between;
                                p.included-database {
                                    padding-top: 6px;
                                    min-width: 100px;
                                    span {
                                        float: left;
                                        height: 20px;
                                        margin-left: 10px;
                                        display: block;
                                        font-size: 12px;
                                        color: #bd1a2d;
                                        padding: 0 10px;
                                        line-height: 20px;
                                        border: 0.8px solid #a91728;
                                        box-sizing: border-box;
                                        border-radius: 10px;
                                        white-space: nowrap;
                                        margin-bottom: 4px;
                                        float: right;
                                    }
                                    // span:last-child {
                                    //     min-width: 100px;
                                    // }
                                }
                            }
                            h5 {
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                line-height: 19px;
                                margin-bottom: 15px;
                                cursor: pointer;
                            }
                            p {
                                font-size: 16px;
                                line-height: 17px;
                                color: #666;
                                margin-bottom: 14px;
                                span {
                                    font-size: 16px;
                                    line-height: 17px;
                                    color: #333;
                                }
                            }
                            p:last-child {
                                margin-bottom: 0;
                                line-height: 26px;
                                margin-top: -5px;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                                span {
                                    color: #666;
                                }
                            }
                        }
                        .w-result {
                            width: 617px;
                        }
                        .article-result {
                            h5.article-title {
                                position: relative;
                                display: flex;
                                img {
                                    width: 20px;
                                    height: 20px;
                                    background: #fff;
                                    border: none;
                                    box-shadow: none;
                                }
                                .article-icon-p {
                                    width: 15px;
                                    height: 17px;
                                    display: block;
                                    background: url(../assets/img/tag-title.png);
                                    margin-right: 8px;
                                }
                                .article-icon-preprint {
                                    width: 19px;
                                    height: 22px;
                                    display: block;
                                    margin-right: 8px;
                                    background: url(../assets/img/oa/icon-ssap.png);
                                    background-size: 100% 100%;
                                    margin-top: -3px;
                                }
                                .article-icon-video {
                                    width: 19px;
                                    height: 19px;
                                    display: block;
                                    margin-right: 8px;
                                    background: url(../assets/img/oa/icon-video.png);
                                    background-size: 100% 100%;
                                    margin-top: 0px;
                                }
                                .article-icon-pdf {
                                    content: 'pdf';
                                    background: #bd1a2d;
                                    width: 24px;
                                    height: 24px;
                                    line-height: 26px;
                                    color: #fff;
                                    font-size: 11px;
                                    text-align: center;
                                    border-radius: 4px;
                                    margin-right: 6px;
                                    margin-top: -4px;
                                    cursor: auto;
                                }

                                .article-title-auto-pdf {
                                    cursor: auto;
                                }
                                .article-icon-collect {
                                    width: 19px;
                                    height: 19px;
                                    display: block;
                                    margin-right: 8px;
                                    background: url(../assets/img/oa/icon-collect.png);
                                    background-size: 100% 100%;
                                    margin-top: -2px;
                                }


                            }
                            // h5.article-title::before {
                            //     content: '';
                            //     width: 15px;
                            //     height: 17px;
                            //     display: block;
                            //     background: url(../assets/img/tag-title.png);
                            //     position: absolute;
                            //     left: 0;
                            //     top: 0;
                            // }
                        }
                    }
                }
            }
            .pagination-wrap {
                display: flex;
                justify-content: center;
            }
        }
    }
}
